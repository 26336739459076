import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import ConfirmLeave from "./ConfirmLeave";

type Props = {
  title?: string;
  back?: {
    to: string | number;
    label: string;
    state?: boolean;
    showWarning?: boolean;
  };
  button?: JSX.Element;
  isConfirmLeaveOpen?: boolean;
  setIsConfirmLeaveOpen?: (open: boolean) => void;
  showWarning?: () => void;
};

const Layout: React.FC<Props & JSX.IntrinsicElements["div"]> = ({
  title,
  back,
  children,
  button,
  isConfirmLeaveOpen = false,
  setIsConfirmLeaveOpen,
  showWarning,
  ...rest
}) => {
  const isNativeDevice = localStorage.getItem("mobile") === "true";
  const navigate = useNavigate();
  return (
    <div {...rest}>
      {!isNativeDevice && (
        <LayoutHeader
          title={title}
          back={back}
          button={button}
          showWarning={showWarning}
        />
      )}
      <LayoutBody>{children}</LayoutBody>
      {isConfirmLeaveOpen && (
        <ConfirmLeave
          isDialogOpen={isConfirmLeaveOpen}
          setIsDialogOpen={setIsConfirmLeaveOpen}
          goBack={() => navigate(-1)}
        />
      )}
    </div>
  );
};

const LayoutHeader = ({
  back,
  title,
  button: Button,
  showWarning,
  ...props
}: Props & JSX.IntrinsicElements["div"]) => {
  const { className, ...rest } = props;
  const navigate = useNavigate();
  const locationState = useLocation().state;

  const isNativeDevice = localStorage.getItem("mobile") === "true";

  if (isNativeDevice) {
    return null;
  }

  return (
    <div className={`p-6 border-b border-gray-200 ${className}`} {...rest}>
      <div className="flex justify-between flex-1">
        <div>
          {back ? (
            back?.showWarning ? (
              <button
                onClick={showWarning}
                className="inline-flex items-center p-1 text-sm"
              >
                <FontAwesomeIcon icon="arrow-left" />
                <span className="ml-2">{back.label}</span>
              </button>
            ) : back?.state === true ? (
              <button
                onClick={() =>
                  navigate(`${back.to}`, { state: { redirect: true } })
                }
                className="inline-flex items-center p-1 text-sm"
              >
                <FontAwesomeIcon icon="arrow-left" />
                <span className="ml-2">{back.label}</span>
              </button>
            ) : typeof back.to === "number" ? (
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center p-1 text-sm"
              >
                <FontAwesomeIcon icon="arrow-left" />
                <span className="ml-2">{back.label}</span>
              </button>
            ) : (
              <Link
                to={back.to}
                state={{ ...locationState }}
                className="inline-flex items-center p-1 text-sm"
              >
                <FontAwesomeIcon icon="arrow-left" />
                <span className="ml-2">{back.label}</span>
              </Link>
            )
          ) : null}
          {title ? (
            <h4 className="text-base font-bold text-gray-900 font-geometria-bold">
              {title}
            </h4>
          ) : null}
        </div>
        <div>{Button}</div>
      </div>
    </div>
  );
};

const LayoutBody: React.FC<JSX.IntrinsicElements["div"]> = ({
  children,
  ...props
}) => {
  const { className, ...rest } = props;
  return (
    <div
      className={`mx-auto max-w-7xl px-4 sm:px-6 md:px-8 py-6 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export { LayoutHeader, LayoutBody };

export default Layout;
