import React from "react";
import { Modal } from "./Dialog";
import { Button } from "./Button";

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: ((open: boolean) => void) | any;
  goBack: () => void;
  confirmText?: string;
};

const ConfirmLeave = ({
  isDialogOpen,
  setIsDialogOpen,
  goBack,
  confirmText = "Are you sure you want to leave? Any data you have entered will be lost."
}: Props) => {
  return (
    <Modal
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      title={"Warning"}
    >
      <div className="flex justify-between flex-col ">
        <p className="mt-2 text-left">{confirmText}</p>
        <div className="mt-4 flex justify-end gap-2">
          <Button color="default" onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={goBack}>
            Leave
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmLeave;
