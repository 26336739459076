import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import AddTicketForm from "../../components/AddTicketForm";
import { useState } from "react";

export default function AddMaintenanceTicketPage() {
  const params = useParams<{ leaseId: string; propertyId: string }>();
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [isConfirmLeaveOpen, setIsConfirmLeaveOpen] = useState(false);

  const showWarning = () => {
    if (isFieldDirty) {
      setIsConfirmLeaveOpen(true);
    }
  };

  return (
    <Layout
      title="Add Issue"
      back={{
        label: "Maintenance Tickets",
        to: `/lease/${params.leaseId}/maintenance/${params.propertyId}`,
        showWarning: isFieldDirty
      }}
      isConfirmLeaveOpen={isConfirmLeaveOpen}
      setIsConfirmLeaveOpen={setIsConfirmLeaveOpen}
      showWarning={showWarning}
    >
      <div className="max-w-lg mx-auto">
        <AddTicketForm
          setIsFieldDirty={(value: boolean) => setIsFieldDirty(value)}
        />
      </div>
    </Layout>
  );
}
